<template>
  <el-tree
    v-if="treeData.length"
    ref="tree"
    :data="treeData"
    :props="defaultProps"
    @check-change="treeNodeCheckChange"
    :default-checked-keys="defaultKey"
    :check-strictly="true"
    :default-expand-all="false"
    show-checkbox
    accordion
    node-key="id"
  ></el-tree>
</template>
<script>
export default {
  name: 'organzitionTree',
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
    defaultCheckKey: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  computed: {
    defaultKey: {
      get() {
        return this.defaultCheckKey
      },
      set(val) {
        this.$emit('update:defaultCheckKey', val)
      },
    },
  },
  mounted() {},
  methods: {
    treeNodeCheckChange(nodeObj, isChecked, childNodeIsChecked) {
      if (this.multiple) {
        let checkList = this.$refs.tree.getCheckedNodes()
        this.$emit('checkChange', checkList)
      } else {
        if (isChecked) {
          this.$emit('checkChange', nodeObj)
          this.$refs.tree.setCheckedNodes([nodeObj])
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped></style>
