<template>
  <div>
    <el-row>
      <el-col :span="24">
        <el-card shadow="always">
          <el-form :inline="true" :model="searchFrom" class="demo-form-inline searchFrom" size="small">
            <el-form-item label="任务状态">
              <el-select v-model="searchFrom.status">
                <el-option label="全部" value=""></el-option>
                <el-option label="已完成" value="1"></el-option>
                <el-option label="待完成" value="2"></el-option>
                <el-option label="退回修改" value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="数据类型">
              <el-select v-model="searchFrom.type">
                <el-option label="全部" value=""></el-option>
                <!-- <el-option label="xxx" value="1"></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="机构">
              <el-popover width="200" trigger="click">
                <organzition-tree
                  :defaultCheckKey.sync="defaultCheckKey"
                  :treeData="treeData"
                  @checkChange="checkChange"
                />
                <el-button slot="reference" class="popover-btn">
                  <div class="popover-content">
                    <span> 请选择机构 </span>
                    <i class="el-icon-arrow-down"></i>
                  </div>
                </el-button>
              </el-popover>
            </el-form-item>
            <el-form-item label="任务创建时间">
              <el-date-picker
                v-model="searchFrom.value"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary">查询</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <div class="table-block">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{ background: '#f7f9fc', border: 'none', color: '#252526' }"
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column type="index" :label="$t('table.order')" width="100" align="center" />
        <el-table-column prop="taskName" :label="$t('table.taskName')" align="center" />
        <el-table-column prop="createTime" :label="$t('table.createTime')" width="150" align="center" />
        <el-table-column prop="deadline" :label="$t('table.deadline')" width="150" align="center" />
        <el-table-column prop="status" :label="$t('table.status')" width="100" align="center" />
        <el-table-column prop="dataType" :label="$t('table.dataType')" width="150" align="center" />
        <el-table-column :label="$t('table.operate')" align="center">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="check(scope.$index)">{{ $t('btn.check') }}</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link :underline="false" type="primary" @click="check(scope.$index)">{{
              $t('btn.templateDownload')
            }}</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link :underline="false" type="primary" @click="importData(scope.$index)">{{
              $t('btn.import')
            }}</el-link>
            <el-divider direction="vertical"></el-divider>
            <el-link :underline="false" type="primary" @click="check(scope.$index)">{{ $t('btn.reimport') }}</el-link>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @changePage="changePage"
        :total="total"
        :page.sync="searchFrom.pageNo"
        :size.sync="searchFrom.pageSize"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      class="upload-dialog"
      :close-on-click-modal="false"
      :before-close="handleDialogClose"
    >
      <template slot="title">
        <h3 class="dialog-title">导入</h3>
      </template>
      <upload-file ref="uploadFile" />
    </el-dialog>
  </div>
</template>

<script>
import pagination from 'src/components/pagination'
import organzitionTree from 'src/components/organzitionTree'
import uploadFile from 'src/components/uploadFile'
import { organTree } from 'src/api/index'
import { convertToFrontByCode } from 'src/utils/utils'

export default {
  name: 'report-import',
  components: { pagination, organzitionTree, uploadFile },
  data() {
    return {
      loading: false,
      searchFrom: {
        status: '',
        type: '',
        organizationId: '',
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      defaultCheckKey: [],
      treeData: [],
      dialogVisible: false,
    }
  },
  mounted() {
    this.getList()
    this.getOrganTree()
  },
  methods: {
    getOrganTree() {
      organTree().then(res => {
        // if (!this.isEdit) {
        //   this.defaultCheckKey = [res[0]['organizationCode']]
        //   this.form['parentOrganizationCode'] = res[0]['organizationCode']
        // }
        this.treeData = convertToFrontByCode(res)
      })
    },
    getList() {
      this.loading = true
      setTimeout(() => {
        const data = [
          {
            id: 1,
            taskName: '6月基础数据导入任务',
            createTime: '2021-07-01 00:00:00',
            deadline: '2021-07-10 24:00:00',
            status: '1',
            dataType: '人员数据',
          },
        ]
        this.tableData = data
        this.total = 1
        this.loading = false
      }, 1000)
      // xxxList(this.searchFrom).then(res => {
      //   this.loading = false
      //   this.tableData = res.data
      //   this.total = res.total
      // })
    },

    check(index) {
      console.log('查看===index', index)
    },

    handleDialogClose() {
      this.$refs['uploadFile'].resetFileList()
      this.dialogVisible = false
    },
    importData(index) {
      console.log('导入===index', index)
      this.dialogVisible = true
    },
    changePage(pageParams) {
      const { pageNo, pageSize } = pageParams
      this.searchFrom = { ...this.searchFrom, pageNo, pageSize }
      this.getList()
    },
    checkChange(organObj) {
      console.log(organObj)
    },
  },
}
</script>

<style lang="scss" scoped>
.popover-btn {
  width: 190px;
  .popover-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
