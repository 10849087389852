<template>
  <el-upload
    class="upload-demo"
    action="https://jsonplaceholder.typicode.com/posts/"
    :headers="headers"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :limit="1"
    accept=".xlsx"
    :on-exceed="handleExceed"
    :file-list="fileList"
    :on-success="onSuccess"
    :on-error="onError"
  >
    <el-button size="small" type="primary">点击上传</el-button>
    <div slot="tip" class="el-upload__tip">只能上传.xlsx文件，且不超过500kb</div>
  </el-upload>
</template>
<script>
export default {
  name: 'uploadFile',
  props: {},
  data() {
    return {
      uploadUrl: process.env.VUE_APP_APP_URL,
      headers: {
        authToken: localStorage.getItem('bmw_yhx_token'),
      },
      fileList: [
        // {
        //   name: 'food.jpeg',
        //   url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
        // },
      ],
    }
  },
  methods: {
    //删除文件之前的钩子
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },

    //移除文件时的钩子
    handleRemove(file, fileList) {
      console.log('handleRemove==', file, fileList)
    },

    //点击已上传的文件时钩子
    handlePreview(file) {
      console.log('handlePreview==', file)
    },

    //文件超出个数限制时的钩子
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`,
      )
    },

    onSuccess(res, file, fileList) {
      console.log('上传成功', res, file, fileList)
    },

    onError(err, file, fileList) {
      console.log('上传失败', err, file)
    },

    resetFileList() {
      this.fileList = []
    },
  },
}
</script>
<style lang="scss" scoped></style>
