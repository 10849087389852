<template>
  <el-pagination
    @size-change="handlePageSizeChange"
    @current-change="onChangePage"
    :current-page.sync="currentPage"
    :page-sizes="[10, 20, 50, 100, 150]"
    :page-size="currentSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    background
  >
  </el-pagination>
</template>
<script>
export default {
  name: 'pagination',
  props: {
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {}
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      },
    },
    currentSize: {
      get() {
        return this.size
      },
      set(val) {
        this.$emit('update:size', val)
      },
    },
  },
  methods: {
    onChangePage(pageNo) {
      if (pageNo) {
        this.$emit('changePage', { pageNo, pageSize: this.currentSize })
      }
    },
    handlePageSizeChange(pageSize) {
      this.$emit('changePage', { pageNo: this.currentPage, pageSize })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-pagination {
  margin: 15px 0 10px 0;
}
</style>
